import SideMenu from "../../../components/sidemenu";
import {Badge, Button, Table} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {Search} from "react-bootstrap-icons";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../../utils/endpoints.json'
import useAuth from "../../../utils/useAuth";

export default function OrderDetails() {

    const {oid} = useParams()
    const [orderData, setOrderData] = useState(null)

    const [orderCartData, setOrderCartData] = useState([])
    const {authenticated, login, logout, token} = useAuth()


    const GenerateCartData = (order) => {
        console.log(order)
        console.log(order.products)
        let orderProducts = order.products
        let items = Object.keys(orderProducts)
        console.log(items)
        const cart = []
        items.forEach((item) => {
            axios.get(endpoints.APICalls + '/product/get?item=' + item)
                .then((resp) => {
                    let position = {
                        id: resp.data.data.id,
                        item: resp.data.data.item,
                        name: resp.data.data.title,
                        sizes: {}
                    }

                    console.log(position)
                    Object.keys(orderProducts[item]).forEach((size) => {
                        position.sizes[size] = orderProducts[item][size]
                    })

                    cart.push(position)
                    console.log(position)
                    setOrderCartData(cart)
                })
        })
    }

    const updateData = () => {
        axios.get(endpoints.APICalls + '/admin/orders?oid=' + oid, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setOrderData({})
                setOrderData(resp.data)
                setOrderCartData([])
                GenerateCartData(resp.data)
            })
    }

    const changeStatus = (status) => {
        axios.put(endpoints.APICalls + '/admin/orders/status', {
            'oid': oid,
            'status': status
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                updateData()
            })
    }

    useEffect(() => {
        updateData()
    }, []);

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Детали заказа</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                {
                    orderData && (
                        <div className={'flex-grow-1 w-auto'}>
                            <div className={'border rounded p-3'}>
                                <h2>Основная информация о заказе</h2>
                                <div className={'d-flex flex-column gap-0'}>
                                    <p className={'m-0'}><b>ID:</b> {orderData.innerId}</p>
                                    <p className={'m-0'}><b>Статус:</b> {orderData.status}</p>
                                    <p className={'m-0'}><b>Сумма:</b> {orderData.totalPrice}</p>

                                    <p className={'m-0'}><b>Дата
                                        создания:</b> {new Date(orderData.creation_date).toLocaleString('ru-ru')}</p>
                                    <p className={'m-0'}><b>Адрес доставки:</b> {orderData.address}</p>
                                </div>
                                <div className={'mt-3 border-top pt-3'}>
                                    <p><b>Установить новый статус</b></p>
                                    <div className={'d-flex gap-3'}>
                                        <Button onClick={() => changeStatus('created')}>Создано</Button>
                                        <Button onClick={() => changeStatus('delivering')}>В доставке</Button>
                                        <Button variant={'success'}
                                                onClick={() => changeStatus('delivered')}>Доставлено</Button>
                                    </div>
                                </div>
                                <div className={'mt-3 border-top pt-3'}>
                                    <Button variant={'danger'} onClick={() => changeStatus('canceled')}>Отменить
                                        заказ</Button>
                                </div>
                            </div>
                            <div className={'border rounded p-3'}>
                                <h2>Пользователь</h2>

                                <div className={'d-flex flex-column gap-0'}>
                                    <p className={'m-0'}><b>Имя:</b> {orderData.user.firstname}</p>
                                    <p className={'m-0'}><b>Телефон:</b> {orderData.user.mobilePhone}</p>
                                    <p className={'m-0'}><b>Почта:</b> {orderData.user.email}</p>
                                </div>
                            </div>
                            <div className={'border rounded p-3 mt-4'}>
                                <h2>Состав заказа</h2>
                                <Table>
                                    <thead>
                                    <tr className={'fw-bold'}>
                                        <td>Артикул</td>
                                        <td>Наименование</td>
                                        <td>Размер</td>
                                        <td>Кол-во</td>
                                        <td></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        orderCartData.map((position) =>
                                            Object.keys(position['sizes']).map((size) =>
                                                <tr>
                                                    <td>{position.item}</td>
                                                    <td>{position.name}</td>
                                                    <td>{size}</td>
                                                    <td>{position['sizes'][size].amount}</td>
                                                </tr>
                                            )
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}
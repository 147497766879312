import SideMenu from "../../../components/sidemenu";
import {Alert, Button, Form} from "react-bootstrap";
import endpoint from '../../../utils/endpoints.json'
import axios from "axios";
import {useState} from "react";
import ProductForm from "../../../components/product_form";
import endpoints from "../../../utils/endpoints.json";
import useAuth from "../../../utils/useAuth";

export default function CreateProduct() {

    const [done, setDone] = useState(false)
    const [created, setCreated] = useState(false)
    const [createdItem, setCreatedItem] = useState('')
    const {authenticated, login, logout, token} = useAuth()


    const productData = {
        name: null,
        price: 0,
        shortDescription: "null",
        description: null,
        tags: null,
        photos: []
    }

    const createProduct = () => {
        console.log(productData)
        let data = new FormData()
        Object.keys(productData).forEach((key) => {
            data.append(key, productData.key)
        })
        axios.post(endpoint.APICalls + '/admin/product/', productData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setDone(true)
                setCreated(true)
                setCreatedItem(resp.data.item)
            })
            .catch((err) => {
                setDone(true)
            })
    }


    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Создание нового товара</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 w-auto'}>
                    {
                        done ? (
                            created ? (
                                <Alert variant={'success'}>
                                    <p><b>Товар успешно создан</b></p>
                                    <p>Ссылка на товар: <a target={'_blank'}
                                                           href={endpoints.ProductBaseLink + createdItem}>{endpoints.ProductBaseLink + createdItem}</a>
                                    </p>
                                </Alert>
                            ) : (
                                <Alert variant={'danger'}>Ошибка при создании товара</Alert>
                            )
                        ) : (
                            <div>
                                <ProductForm dataHolder={productData}/>
                                <Button variant={'success'} onClick={createProduct}>Создать
                                </Button>
                            </div>
                        )
                    }

                </div>
            </div>

        </div>
    )
}
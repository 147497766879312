import {Alert, Button, Form} from "react-bootstrap";
import data from "bootstrap/js/src/dom/data";
import {useEffect, useState} from "react";

export default function ProductForm({dataHolder, readyData = {}}) {

    const [ph, setPh] = useState([])

    // const addPhoto = () => {
    //     let e = document.querySelector('#photoloader')
    //
    //     dataHolder.photos = e.files
    //
    //     console.log(dataHolder)
    // }

    useEffect(() => {
        console.log(ph)
    }, [ph]);

    return (
        <div>

            <Alert variant={'danger'}>Все поля обязательны к заполнению</Alert>

            <Form>
                <Form.Group className="mb-3" controlId="formGroupName">
                    <Form.Label>Наименование товара</Form.Label>
                    <Form.Control defaultValue={readyData.title} type="text" placeholder="Наименование"
                                  onChange={(e) => dataHolder.name = e.target.value}/>
                < /Form.Group>
                <Form.Group className="mb-3" controlId="formGroupPrice">
                    <Form.Label>Цена</Form.Label>
                    <Form.Control defaultValue={readyData.price} type="number" placeholder="Цена"
                                  onChange={(e) => dataHolder.price = e.target.value}/>
                </Form.Group>
                {/*<Form.Group className="mb-3" controlId="formGroupShortDescription">*/}
                {/*    <Form.Label>Краткое описание</Form.Label>*/}
                {/*    <Form.Control defaultValue={readyData.shortDescription} type="text" placeholder="Краткое описание"*/}
                {/*                  onChange={(e) => dataHolder.shortDescription = e.target.value}/>*/}
                {/*</Form.Group>*/}
                <Form.Group className="mb-3" controlId="formGroupDescription">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control defaultValue={readyData.description} as={'textarea'} rows={3} placeholder="Описание"
                                  onChange={(e) => dataHolder.description = e.target.value}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupTags">
                    <Form.Label>Фильтры (через запятую)</Form.Label>
                    <Form.Control defaultValue={readyData.rowtags} type="text" placeholder="Теги"
                                  onChange={(e) => dataHolder.tags = e.target.value}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupCover">
                    <Form.Label>Обложка</Form.Label>
                    <Form.Control id={'photoloader'} type="file" placeholder="Обложка" multiple onChange={(e) => {dataHolder.photos = e.target.files} }/>
                </Form.Group>

            </Form>
        </div>
    )
}
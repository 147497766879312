import SideMenu from "../../../components/sidemenu";
import {Button, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {PenFill, Search} from "react-bootstrap-icons";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from "../../../utils/endpoints.json"
import useAuth from "../../../utils/useAuth";

export default function Showcases() {

    const [showcases, setShowcases] = useState([])
    const {authenticated, login, logout, token} = useAuth()


    useEffect(() => {
        axios.get(endpoints.APICalls + '/showcases', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setShowcases(resp.data)
            })
    }, []);

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Витрины</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 w-auto'}>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>ID</td>
                            <td>Имя</td>
                            <td>Продукты</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            showcases.map((showcase) =>
                                <tr>
                                    <td>{showcase.id}</td>
                                    <td>{showcase.name}</td>
                                    <td>
                                        {
                                            showcase.products.map((product) =>
                                                <p>{product.title}</p>
                                            )
                                        }
                                    </td>
                                    <td>
                                        <Link
                                            to={`/dashboard/showcases/${showcase.id}/edit`}><Button><PenFill/></Button></Link>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}
import logo from './logo.svg';
import './App.css';
import {Button, Form} from "react-bootstrap";
import {useState} from "react";
import axios from "axios";
import endpoints from "./utils/endpoints.json"
import useAuth from "./utils/useAuth"

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {authenticated, login, logout} = useAuth()

    // const Login = async (event) => {
    //     event.preventDefault();
    //
    //     try {
    //         const response = await axios.post(endpoints.APICalls + '/token/', {
    //             "mobilePhone": username,
    //             "password": password
    //         });
    //         const {refresh, access} = response.data;
    //         localStorage.setItem('refresh_token', refresh);
    //         localStorage.setItem('access_token', access);
    //         console.log('Успешно авторизованы!');
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };


    return (
        <div className="App container">
            <div className={'vh-100 d-flex flex-column gap-5 justify-content-center align-items-center'}>
                <h1>Авторизация</h1>
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email" onChange={(e) => setUsername(e.target.value)}/>
                    < /Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control type="password" placeholder="Пароль"
                                      onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Group>
                    <Button onClick={() => login(username, password)}>Войти</Button>
                </Form>
            </div>
        </div>
    );
}

export default App;

import SideMenu from "../../../components/sidemenu";
import {Badge, Button, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Search} from "react-bootstrap-icons";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../../utils/endpoints.json'
import useAuth from "../../../utils/useAuth";

export default function Filters() {

    const [tags, setTags] = useState([])
    const {authenticated, login, logout, token} = useAuth()

    useEffect(() => {
        axios.get(endpoints.APICalls + '/tags', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setTags(resp.data)
            })
    }, []);

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Фильтры</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 w-auto'}>
                    <Link to={'/dashboard/filters/create'}><Button >Добавить новый фильтр</Button></Link>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>ID</td>
                            <td>Фильтр</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            tags.map((tag) =>
                                <tr>
                                    <td>{tag.id}</td>
                                    <td>{tag.name}</td>
                                    <td></td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>

        </div>
    )
}
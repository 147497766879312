import {Link} from "react-router-dom";
import {Button, Stack} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../utils/endpoints.json'
import useAuth from "../utils/useAuth";

export default function SideMenu() {

    const [userData, setUserData] = useState()
    const {authenticated, login, logout} = useAuth()

    useEffect(() => {
        axios.get(endpoints.APICalls + '/users/get/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                console.log(resp.data)
                setUserData(resp.data.user)
            })
    }, [authenticated]);

    const logoutUser = () => {
        logout()
        window.location = '/'
    }

    return (
        <Stack gap={2} className={'w-auto'}>
            {
                authenticated && (
                    userData && (
                        <div className={'alert alert-info w-75'}>
                            <p><b>Пользователь: {userData.firstname} {userData.surname}</b></p>
                            <Button variant={'danger'} onClick={logoutUser}>Выйти</Button>
                        </div>
                    )
                )

            }
            <div className={'d-flex flex-column gap-2'}>
                <Link to={'/dashboard/products/'}>Товары</Link>
                <Link to={'/dashboard/support/'}>Поддержка</Link>
                <Link to={'/dashboard/filters/'}>Фильтры</Link>
                <Link to={'/dashboard/filtersgroups/'}>Группы фильтров</Link>
                <Link to={'/dashboard/orders/'}>Заказы</Link>
                <Link to={'/dashboard/showcases/'}>Витрины</Link>
                <Link to={'/dashboard/storage/'}>Склад</Link>
            </div>
        </Stack>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import MainAdmin from "./pages/admin_main";
import AdminProducts from "./pages/inmain/products/products";
import EditProduct from "./pages/inmain/products/edit_product";
import CreateProduct from "./pages/inmain/products/create_product";
import SupportRequests from "./pages/inmain/support/support_requests";
import SupportRequestDetails from "./pages/inmain/support/support_request_details";
import Filters from "./pages/inmain/filters/filters";
import CreateFilter from "./pages/inmain/filters/filters_create";
import FiltersGroups from "./pages/inmain/filters_groups/filters_groups";
import CreateFilterGroup from "./pages/inmain/filters_groups/filters_groups_create";
import AddFilterToFiltersGroup from "./pages/inmain/filters_groups/filters_groups_add";
import Orders from "./pages/inmain/orders/orders";
import OrderDetails from "./pages/inmain/orders/order_details";
import Showcases from "./pages/inmain/showcases/showcases";
import ShowcaseEdit from "./pages/inmain/showcases/showcase_edit";
import Storage from "./pages/storage/storage";
import StorageContainer from "./pages/storage/storage_container";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/dashboard",
        element: <MainAdmin/>
    },
    {
        path: "/dashboard/products",
        element: <AdminProducts/>
    },
    {
        path: "/dashboard/products/edit/:item",
        element: <EditProduct/>
    },
    {
        path: '/dashboard/products/create',
        element: <CreateProduct/>
    },
    {
        path: '/dashboard/support',
        element: <SupportRequests/>
    },
    {
        path: '/dashboard/support/request/:rid',
        element: <SupportRequestDetails/>
    },
    {
        path: '/dashboard/filters',
        element: <Filters/>
    },
    {
        path: '/dashboard/filters/create',
        element: <CreateFilter/>
    },
    {
        path: '/dashboard/filtersgroups/',
        element: <FiltersGroups/>
    },
    {
        path: '/dashboard/filtersgroups/create',
        element: <CreateFilterGroup/>
    },
    {
        path: '/dashboard/filtersgroups/:gid/edit',
        element: <AddFilterToFiltersGroup/>
    },
    {
        path: '/dashboard/orders',
        element: <Orders/>
    },
    {
        path: '/dashboard/orders/:oid/details',
        element: <OrderDetails/>
    },
    {
        path: '/dashboard/showcases',
        element: <Showcases/>
    },
    {
        path: '/dashboard/showcases/:sid/edit',
        element: <ShowcaseEdit/>
    },
    {
        path: '/dashboard/storage',
        element: <Storage/>
    },
    {
        path: '/dashboard/storage/:item',
        element: <StorageContainer />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div>
        <RouterProvider router={router}/>
    </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

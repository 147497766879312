import SideMenu from "../../../components/sidemenu";
import {Link} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import {PlusSquareFill, Search, TrashFill} from "react-bootstrap-icons";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../../utils/endpoints.json'
import useAuth from "../../../utils/useAuth";

export default function Orders() {

    const [ordersList, setOrdersList] = useState([])
    const {authenticated, login, logout, token} = useAuth()


    useEffect(() => {
        axios.get(endpoints.APICalls + '/admin/orders', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setOrdersList(resp.data)
            })
    }, []);

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Заказы</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 w-auto'}>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>ID</td>
                            <td>Дата</td>
                            <td>Сумма</td>
                            <td>Статус</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            ordersList.map((order) =>
                                <tr>
                                    <td>{order.innerId}</td>
                                    <td>{order.creation_date}</td>
                                    <td>{order.totalPrice}</td>
                                    <td>{order.status}</td>
                                    <td>
                                        <Link to={`/dashboard/orders/${order.id}/details`}><Button
                                            variant={'secondary'}><Search/></Button></Link>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from "../../../utils/endpoints.json";
import SideMenu from "../../../components/sidemenu";
import {Link, useParams} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import {FileMinusFill, FilePlusFill, Link45deg, PencilSquare, PlusSquareFill} from "react-bootstrap-icons";
import useAuth from "../../../utils/useAuth";

export default function ShowcaseEdit() {

    const {sid} = useParams()
    const [catalog, setCatalog] = useState([])
    const [showcaseItems, setShowcaseItems] = useState([])
    const {authenticated, login, logout, token} = useAuth()


    const UpdateData = () => {
        axios.get(endpoints.APICalls + "/catalog", {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                console.log(resp.data)
                setCatalog(resp.data)
                axios.get(endpoints.APICalls + '/showcases?sid=' + sid)
                    .then((resp) => {
                        setShowcaseItems(resp.data)
                    })
            })
    }

    const SwitchProductInShowcase = (pid) => {
        axios.post(endpoints.APICalls + '/showcases/switch/', {
            'sid': sid,
            'pid': pid
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                UpdateData()
            })
    }

    useEffect(() => {
        UpdateData()
    }, []);

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Редактирование витрины</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 w-auto'}>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>Артикул</td>
                            <td>Наименование</td>
                            <td>Цена</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            catalog.map((item) =>
                                <tr>
                                    <td>{item.item}</td>
                                    <td>{item.title}</td>
                                    <td>{item.price}</td>
                                    <td className={'d-flex gap-1'}>
                                        {
                                            showcaseItems.includes(item.item) ? (
                                                <Button variant={'danger'}
                                                        onClick={() => SwitchProductInShowcase(item.id)}><FileMinusFill/></Button>
                                            ) : (
                                                <Button variant={'success'}
                                                        onClick={() => SwitchProductInShowcase(item.id)}><FilePlusFill/></Button>
                                            )
                                        }

                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>

        </div>
    )
}
import SideMenu from "../../../components/sidemenu";
import {Link} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../../utils/endpoints.json'
import {Plus, PlusSquareDotted, PlusSquareFill, Trash, TrashFill} from "react-bootstrap-icons";
import useAuth from "../../../utils/useAuth";

export default function FiltersGroups() {

    const [tagsGroups, setTagsGroups] = useState({})
    const {authenticated, login, logout, token} = useAuth()

    const updateData = () => {
        axios.get(endpoints.APICalls + '/tags/groups?wid=1', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setTagsGroups({})
                setTagsGroups(resp.data)
            })
    }

    const removeFilterGroup = (gid) => {
        axios.delete(endpoints.APICalls + '/tags/groups?gid=' + gid, {
            "gid": gid
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                updateData()
            })
    }

    useEffect(() => {
        axios.get(endpoints.APICalls + '/tags/groups?wid=1', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setTagsGroups(resp.data)
            })
    }, []);

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Группы фильтров</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 w-auto'}>
                    <Link to={'/dashboard/filtersgroups/create'}><Button>Создать новую группу</Button></Link>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>Название</td>
                            <td>Теги</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Object.keys(tagsGroups).map((group) =>
                                <tr>
                                    <td>{group}</td>
                                    <td>{Object.keys(tagsGroups[group]['body']).join(', ')}</td>
                                    <td className={'d-flex gap-2'}>
                                        <Link to={`/dashboard/filtersgroups/${tagsGroups[group]['id']}/edit`}><Button
                                            variant={'success'}><PlusSquareFill/></Button></Link>
                                        <Button variant={'danger'}
                                                onClick={() => removeFilterGroup(tagsGroups[group]['id'])}><TrashFill/></Button>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}
import SideMenu from "../../../components/sidemenu";
import {Alert, Button, Form, FormText, Table} from "react-bootstrap";
import {useState} from "react";
import axios from "axios";
import endpoints from '../../../utils/endpoints.json'
import useAuth from "../../../utils/useAuth";

export default function CreateFilter() {

    const [tagName, setTagName] = useState('')
    const [done, setDone] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')
    const {authenticated, login, logout, token} = useAuth()


    const createFilter = () => {
        axios.post(endpoints.APICalls + '/tags/', {
            'tagName': tagName
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                setSuccess(true)
                setDone(true)
            })
            .catch((err) => {
                setDone(true)
                switch (err.response.status) {
                    case 400:
                        setError('Такой фильтр уже есть, либо фильтр написан в неправильном формате')
                        break

                    default:
                        setError('Внутренняя ошибка создания фильтра, обратитесь в службу поддержки')
                        break
                }
            })
    }

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Создание нового фильтра</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 w-auto'}>
                    {
                        done ? (
                            success ? (
                                <Alert variant={'success'}>
                                    <p><b>Фильтр создан</b></p>
                                    <p>Чтобы он отображался добавьте его в одну из групп фильтров</p>
                                </Alert>
                            ) : (
                                <Alert variant={'danger'}>
                                    <p><b>Ошибка создания фильтра</b></p>
                                    <p>{error}</p>
                                </Alert>
                            )
                        ) : (
                            <>
                                <Form.Group className="mb-3" controlId="formGroupShortDescription">
                                    <Form.Label>Название фильтра</Form.Label>
                                    <Form.Control type="text"
                                                  placeholder="Название фильтра"
                                                  onChange={(e) => setTagName(e.target.value)}/>
                                    <FormText>При создании фильтра, все буквы переведутся в нижний регистр</FormText>
                                </Form.Group>
                                <Button onClick={createFilter}>Создать</Button>
                            </>
                        )
                    }

                </div>
            </div>

        </div>
    )
}